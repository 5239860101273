import React from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Title from './Title';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

export default function BasicTextFields(props) {
    const classes = useStyles();
    const { onSubmitOrder } = props;

    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [selectedName, setSelectedName] = React.useState('');
    const [selectedShipTo, setSelectedShipTo] = React.useState('');
    const [selectedCreditCard, setSelectedCreditCard] = React.useState('');
    const [selectedAmount, setSelectedAmount] = React.useState('');

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        //const { name, amount, shipTo, creditCard, date } = this.state;
        //alert('A form was submitted: ' + this.state);

        const body = { body: { date: selectedDate, name: selectedName, shipTo: selectedShipTo, creditCard: selectedCreditCard, amount: selectedAmount } }

        axios.post('https://fxmvoulzhe.execute-api.us-east-1.amazonaws.com/production/orders', body)
            .then((res) => {
                console.log(res);
                const newOrder = JSON.parse(res.data.body);
                onSubmitOrder(newOrder);
            });
    }
    return (
        <div>
            <Title>New Order</Title>
            <form className={classes.root} noValidate autoComplete="off">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Order Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <TextField id="standard-basic" label="Name" value={selectedName} onChange={(e) => setSelectedName(e.target.value)} />
                <TextField id="standard-basic" label="Ship To" value={selectedShipTo} onChange={(e) => setSelectedShipTo(e.target.value)} />
                <TextField id="standard-basic" label="Credit Card" value={selectedCreditCard} onChange={(e) => setSelectedCreditCard(e.target.value)} />
                <TextField id="standard-basic" label="Sale Amount" value={selectedAmount} onChange={(e) => setSelectedAmount(e.target.value)} />
                <Button variant="contained" onClick={handleSubmit}>Add Order</Button>
            </form>
        </div>
    );
}