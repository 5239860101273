import React, { Component } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';

class Orders extends Component {

  renderTableData() {
    return this.props.orders.map((order) => {
      const { id, name, amount, shipTo, creditCard, date } = order //destructuring
      return (
        <TableRow key={id} order={date}>
          <TableCell>{date}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{shipTo}</TableCell>
          <TableCell>{creditCard}</TableCell>
          <TableCell align="right">{amount}</TableCell>
        </TableRow>
      )
    })
  }

  render() {
    return (
      <React.Fragment>
        <Title>Recent Orders</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Ship To</TableCell>
              <TableCell>Credit Card</TableCell>
              <TableCell align="right">Sale Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderTableData()}
          </TableBody>
        </Table>
      </React.Fragment>
    )
  }
}

export default Orders;